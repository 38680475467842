import { app } from './app';
export enum PermissionSets {
  ADMIN = 'Admin',
  BASIC = 'Basic',
  ADVANCED = 'Advanced',
  SIGNER = 'Signer',
  OWNER = 'Owner',
  CUSTOM = 'Custom',
}

export enum GranularPermissions {
  BATCHES = 'Batches',
  FUNDING = 'Funding',
  MERCHANT_STATEMENTS = 'Merchant Statements',
  SIGN_UP_FOR_NEW_PRODUCTS = 'Sign Up For New Products',
  ACCOUNT_UPDATES = 'Account Updates',
  VIEW_ACCOUNT_SETTINGS = 'View Account Settings',
  ORDER_EQUIPMENT = 'Order Equipment',
  MANAGE_USERS = 'Manage Users',
  MANAGE_OWNERS = 'Manage Owners',
}

export const InternalDomains = ['e-hps.com', 'tsys.com', 'globalpayments.com', 'globalpay.com', 'cayan.com'];

export type UserColorPalette = {
  primary: string;
  secondary: string;
};

export const PrimaryContact = 'PRIMARY_CONTACT';

export const UNKNOWN = 'Unknown';

export type User = {
  userEmail: string;
  name: string;
  firstName: string;
  permissionSet: PermissionSet;
  lastName: string;
  active: boolean;
  impersonateMode: boolean;
  totalItemsInCart: number;
  overrideActive: boolean;
  activeCustomer: CustomerId | null;
  userHasPaymentsAccounts: boolean;
  userHasPayrollAccounts: boolean;
  activeCustomerInformation: CustomerInformation;
  flags: UserFlags;
  isVerified: boolean;
  assignedCustomerApplications?: Array<app>;
  pendingOwnershipVerifications?: Array<PendingOwnershipVerifications>;
  callToActions: callToAction;
  isMyAccountEligible: boolean;
};

export type PendingOwnershipVerifications = {
  identifierValue: string;
  identifierType: string;
  dbaName: string;
  remainingAttempts: number;
  maximumAttempts: number;
  verified: boolean;
};

export type UserContextApiResponse = {
  userEmail: string;
  activeCustomer: CustomerId | null;
  isOwner: boolean;
  isSigner: boolean;
  active: boolean;
  permissionSet: PermissionSet;
  firstName: string;
  lastName: string;
  overrideActive: boolean;
  totalItemsInCart: number;
  totalCustomerCount: number;
  userHasPaymentsAccounts: boolean;
  userHasPayrollAccounts: boolean;
  activeCustomerInformation: CustomerInformation;
  flags: UserFlags;
  isVerified: boolean;
  assignedCustomerApplications: Array<app>;
  pendingOwnershipVerifications?: Array<PendingOwnershipVerifications>;
  callToActions: callToAction;
  impersonateMode: boolean;
  isMyAccountEligible: boolean;
};

export interface UserInterface extends User {
  selectedCustomer: Customer;
  selectActiveCustomer: (selectedCustomer: Customer) => void;
  setActiveCustomer: (id: Customer) => void;
  getImpersonateUser: (targetEmail: string, targetCustomerId: CustomerId) => void;
  logout: () => void;
}

export type CustomerInformation = {
  city: string;
  state: string;
  customerHasActivePaymentsManagerPlus: boolean;
  customerHasActivePayrollPlus: boolean;
  customerTerminated: boolean;
  customerEligibleForAccountUpdater: boolean;
  dbaName: string | null;
  highRisk: boolean;
  isValidCartDbaState: boolean;
  isValidCartDbaStatePr: boolean;
  isValidCartLegalState: boolean;
  isValidDbaAddress: boolean;
  isValidLegalAddress: boolean;
  isAddToCartAllowed: boolean;
  ownerEmails: Array<string>;
  paymentsCustomerType: PaymentsCustomerType | null;
  mcc?: number;
  affiliateId?: string;
  supportContact: SupportContact;
  forbiddenPaths: string[];
};

export type UserFlags = {
  showFundingReport: boolean;
  showIntegrations: boolean;
  showHiringAndOnBoarding: boolean;
  showTimeAndAttendance: boolean;
  showBenefitsAdministration: boolean;
  showOnlineStore: boolean;
  showBillPay: boolean;
  showAccountSettings: boolean;
  showSGASimulator: boolean;
  showCTACard: boolean;
  showPermissionOverride: boolean;
  showImpersonateUser: boolean;
  showStatementReport: boolean;
  showMyEquipment: boolean;
  showConfiguration: boolean;
};

export type CustomerId = {
  idType: string;
  idValue: string;
};

export type Customer = {
  customerId: CustomerId;
  dbaName: string;
  state: string;
  city: string;
  status?: PendingVerificationStatus;
};
export interface VerifyOwnershipResult {
  verified: boolean;
  remainingAttempts: number;
  maximumAttempts: number;
}

export interface CustomerAssignment extends Customer {
  accountEmail: string;
}

export type UserDetail = {
  email: string;
  name: string;
  permissionSetName: string;
  status: string;
  lastLoginUTC: string;
};

export type userToImpersonateDetail = {
  email: string;
  name: string;
  dbaName: string;
  permission: string;
  status: string;
  identifier: string;
  identifierType: string;
};

export type UsersGridResult = {
  userManagementData: {
    totalRecords: number;
    filteredResults: Array<UserDetail>;
  };
};

export type UsersToImpersonateResult = {
  searchUsers: {
    results: Array<userToImpersonateDetail>;
    totalRecords: number;
  };
};

export enum IdentifierTypes {
  CLIENT_ID = 'CLIENT CODE',
  MERCHANT_ID = 'MERCHANT NUMBER',
  MERCHANT_SEQUENCE_KEY = 'MERCHANT SEQUENCE KEY',
}

export enum PendingVerificationStatus {
  UNVERIFIED = 'Unverified',
  PENDING = 'Pending',
  UNDER_REVIEW = 'Under Review',
}

export type MDMAddress = {
  name: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
};

export type MDMCustomerAddress = {
  dba: MDMAddress;
  legal: MDMAddress;
};

export enum AddressTypes {
  DBA = 'DBA',
  LEGAL = 'LEGAL',
}

export enum PaymentsCustomerType {
  NetNew = 'NetNew',
  Existing = 'Existing',
}

export enum ResultSortDirection {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export type callToAction = {
  addNewUser: boolean;
};

export type PendingOwner = {
  email: string;
  accountId?: number;
};

export type ApproveOwnerResult = {
  success: boolean;
};

export type PermissionSet = {
  name: string;
  permissions: GranularPermissions[];
  isRestricted?: boolean;
};

export type AllPermissionsResult = {
  permissionSets: PermissionSet[];
  customPermissionsByCategory: PermissionSet[];
};

export type StaffMemberInviteResult = {
  InviteResult: string;
};

export type SupportContact = {
  phone: string;
  ext?: string;
};
